.filterTag {
    display: flex;
    align-items: center;
    /* font-size: 11px; */
    // background: rgb(46 159 202 / 50%);
    border: 1px solid;
    padding: 6px 10px;
    border-radius: 6px;
    height: 13px;

    .userDropdown {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: -webkit-fill-available;
        justify-content: space-between;
        padding: 0px 10px;

        .dropdownHeader {
            display: flex;
            align-items: center;
        }
    }

    .userDropdownOpen {
        width: 166px;
    }

    .dropdown {
        display: flex;

        position: absolute;
        flex-direction: column;
        /* background: antiquewhite; */
        background-color: rgba(40, 167, 69, 0.2);
        /* background-color: rgb(76 172 98 / 85%); */
        border-color: rgba(40, 167, 69, 0.45);
        border: 1px solid rgba(40, 167, 69, 0.4509803922);
        margin-top: 27px;
        border-radius: 6px;
        -webkit-backdrop-filter: blur(3.5px);
        backdrop-filter: blur(3.5px);
        box-shadow: 0px 4px 8px 0px rgba(163, 59, 8, 0.08), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        .userDropdownItem {
            padding: 4px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: -webkit-fill-available;

            span {
                width: 140px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.609);
            }
        }
    }

    .label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        
        font-size: 12px;
        width: 102px;
    }

    .closeIcon {
        height: 17px;
        cursor: pointer;
    }
}

.dropdownFiltertag {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 0px;
}