@import "src/variable/colors.scss";

.activity {
  display: flex;
  flex-direction: column;
  margin: 33px 15px 15px 25px;
  background: $gray-100;
  gap: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    .title {
      h3 {
        color: $dark;

        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .filter {
      display: flex;
      gap: 4px;
      .filterBtn,
      .viewall {
        display: flex;
        gap: 7px;
        // margin: 0px 16px;
        height: 35px;
        background: $dark;
        border: radius 6px;
        align-items: center;

        color: $primary-white;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: none;
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 21px;
    .activityContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .activityCard {
        display: flex;
        flex-direction: column;
        width: 300px;
        min-height: 180px;
        padding: 20px;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border: 1px solid rgba(0, 0, 0, 0.2);

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;

          .username {
            display: flex;
            gap: 5px;
            align-items: center;

            .image {
              //   width: 48px;
              //   height: 47px;
            }
            .name {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
          .detailbtn {
            button {
              display: flex;
              gap: 7px;
              // margin: 0px 16px;
              height: 35px;
              background: $dark;
              border: radius 6px;
              align-items: center;

              color: $primary-white;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-transform: none;
            }
          }
        }

        .imageContent {
          display: flex;
          img {
            width: 100%;
            height: 150px;
            border-radius: 10px;
            border: 1px solid #c2c0c0;
          }

          .addFile {
            width: 30px;
            height: 30px;
            position: absolute;
            background-color: #fb8278;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 115px 0px 0px 6px;
            cursor: pointer;

            button {
              width: 34px;
              height: 30px;
              top: 0px;
              left: 60px;
            }
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          gap: 5px;
          .amount,
          .wallet {
            display: flex;
            justify-content: space-between;
            .amountText {
              color: red;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .walletText {
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .date,
            .status {
              text-align: right;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .detailInfo {
          display: flex;
          flex-direction: column;
          gap: 26px;
          align-items: center;

          .walletname {
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .merchantname {
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .date {
            color: rgba(0, 0, 0, 0.6);

            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .participantname {
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .viewPhoto {
            border-radius: 10px;
            height: 50px;
            font-size: 16px;
            font-weight: 700;
            border: 1px solid rgba(126, 126, 126, 0.6);
            background: $dark;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            text-transform: none;
          }
        }
      }

      .photoCard {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding: 20px;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #ffffff40;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border: 1px solid rgba(0, 0, 0, 0.2);
        align-items: center;

        .photoReceipt {
          display: flex;
          max-height: 222px;
          overflow-y: auto;

          .receipt,
          .photo {
            display: flex;
            // gap: 10px;
            flex-wrap: wrap;
            justify-content: center;

            img {
              width: 220px;
              height: 140px;
            }

            .noData {
              display: flex;
              justify-content: space-around;
              border: 2px dashed #b7b7b7;
              padding: 25px;
              border-radius: 10px;
              display: flex;
              margin-top: 10%;
              color: #878787;
              margin: 4.55em 3rem;
            }
          }
        }
      }

      .addbuttons {
        display: flex;
        /* padding: 0px 10px 0px 10px; */
        flex-direction: column;
        gap: 5px;
        width: 99%;

        .uploadphoto {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          .addReceipt,
          .addPhoto {
            display: flex;
            gap: 6px;
            height: 50px;
            width: 100%;
            font-size: 17px;
            font-weight: 700;
            border-radius: 6px;
            border: 1px solid rgba(0, 0, 0, 0.6);
            color: #000;
            background: rgba(217, 217, 217, 0.3);

            .logo {
              display: flex;
            }
            .text {
              text-align: center;
              font-size: 12px;
              font-style: normal;
              line-height: normal;
              text-transform: none;
            }
          }
        }
      }
    }
    .back {
      position: absolute;
      top: 81px;
      right: 0px;
      display: flex;
      padding-right: 23px;

      .backbtn {
        display: flex;
        gap: 7px;
        // margin: 0px 16px;
        height: 35px;
        background: $dark;
        border: radius 6px;
        align-items: center;

        color: $primary-white;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: none;
      }
    }
  }

  .loadMoreContainer {
    display: flex;
    justify-content: center;
    margin: 10px 0px;

    button {
      display: flex;
      gap: 7px;
      // margin: 0px 16px;
      height: 35px;
      background: $dark;
      border: radius 6px;
      align-items: center;

      color: $primary-white;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: none;
    }
  }
}

.filterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  gap: 30px;
  h3 {
  }
  .filterContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .bottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    gap: 10px;
    .orderCardBtn {
      border-radius: 10px;
      background-color: $dark;
      text-transform: none;
    }
    .clearBtn {
      border-radius: 10px;
      color: $dark;
      border-color: $dark;
      text-transform: none;

    }
  }
}

@media screen and (max-width: 800px) {
  .activity {
    .header {
      .title {
        h3 {
          font-size: 20px;
        }
      }
      .filter {
        .filterBtn {
          font-size: 12px;
        }
      }
    }
    .content {
      .activityContainer {
        .activityCard {
          width: 250px;
          .header {
            .detailbtn {
              button {
                font-size: 12px;
              }
            }
          }

          .info {
            .amount,
            .wallet {
              .amountText {
                font-size: 14px;
              }
              .walletText {
                font-size: 12px;
              }
              .date,
              .status {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .activity {
    .content {
      .activityContainer {
        .photoCard {
          width: 250px;
          .photoReceipt {
            .receipt,
            .photo {
              img {
                width: 250px;
                height: 150px;
              }
            }
          }
        }
      }
    }
  }
}
