@import "src/variable/colors.scss";

* {
  margin: 0;
  padding: 0;
}

.userPage {
  display: flex;
  flex-direction: column;
  background-color: $bg-color;

  .userPageWelCard {
    display: flex;
    margin-top: 33px;
    background: url("../../assets/images/pinkBg.png");
    background-size: cover;
    background-repeat: no-repeat;

    .welCardLOver {
      height: 75px !important;
      width: 75px !important;
    }
  }
  .userPageHead {
    display: flex;
    // max-width: 1166px;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
    margin: 24px;

    .walletMemberOver {
      min-height: 16rem;
    }

    // .editIcon {
    //   width: 14px;
    //   height: 14px;
    //   color: #64748b;
    // }
  }
  .userPageMid {
    display: flex;
    align-items: flex-start;
    // gap: 30px;
    margin: 17px;
    justify-content: space-between;
    margin-top: 0;
    height: auto;

    .recentTransOver {
      // max-width: 58vw;
    }

    .transCards {
      display: flex;
      flex-direction: column;
      // margin-right: 72.5px;
      gap: 10px;
      // height: 543px;
      .WalletTransCardOver {
        // display: flex;

        .balCardOver {
          display: flex;
          height: 150px;
          width: auto;
          min-width: 200px;
          max-width: 300px;
          gap: 8px;
        }
      }
    }
  }
  .userPageBottom {
    margin: 17px;
    margin-top: 0;

    .cardListContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: auto;
      height: auto;
      padding: 24px 16px;
      border-radius: 12px;
      border: 1px solid $gray-300;
      background: $primary-white;
      /* Shadow cards */
      box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
      margin: 10px;
      .cardListTable {
        display: flex;
        height: auto;
        padding: 16px 0px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        border-radius: 12px;
        border: 1px solid $gray-200;
        background: $primary-white;

        /* Shadow cards */

        .cardListHead {
          display: flex;
          width: 95%;
          margin: 0px 16px;
          justify-content: space-between;
          gap: 16px;

          .cardListHeadD {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h3 {
              color: #1e293b;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
            }

            p {
              color: #64748b;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
            }
          }
          .orderCardBtn {
            display: flex;
            gap: 7px;
            height: 35px;
            background: $dark;
            border: radius 6px;
            align-items: center;

            color: $primary-white;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: none;
          }
        }

        .tableIn {
          width: -webkit-fill-available;
          overflow-x: auto;

          table {
            border-collapse: collapse;
            width: 100%;

            thead {
              height: 40px;
              border: 1px solid $gray-300;
              background: $gray-100;
              border-left: none;
              border-right: none;
              background: $gray-100;

              tr th {
                padding: 8px 0px;
                color: rgba(100, 116, 139, 0.7);
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                text-align: left;
                padding: 0px 20px;
              }
            }

            tbody {
              tr {
                padding: 0px 16px;
                border: none;
                border-top: 1px solid $gray-400;
                td {
                  padding: 9px 20px;

                  text-align: left;
                  color: $secondary;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 886px) {
  .userPage {
    .userPageMid {
      display: flex;
      flex-wrap: wrap;
      height: auto !important;

      .recentTransOver {
        max-width: 100vw !important;
      }

      .transCards {
        display: flex;
        flex-direction: row !important;
        padding: 0px !important;
        flex-wrap: wrap;
        height: auto !important;
      }
    }

    .userPageBottom {
      .cardListContainer {
        .cardListTable {
          .cardListHead {
            .cardListHeadD {
              h3 {
                font-size: 12px;
                font-weight: 500;
              }
              p {
                font-size: 10px;
                font-weight: 400;
              }
            }

            .orderCardBtn {
              font-size: 12px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}
