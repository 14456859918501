@import "../../../variable/colors.scss";
.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }

      // .email {
      //   display: flex;
      //   flex-direction: column;
      //   gap: 8px;

      //   @include labelInput;
      // }
      // .name {
      //   display: flex;
      //   flex-direction: row;
      //   gap: 40px;

      //   .Fname {
      //     display: flex;
      //     flex-direction: column;
      //     gap: 8px;
      //     @include labelInput;
      //   }
      //   .Lname {
      //     display: flex;
      //     flex-direction: column;
      //     gap: 8px;
      //     @include labelInput;
      //   }
      // }

      .newUser {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .email {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: 8px;
          @include labelInput;
          // width: 50vw;
        }

        .name,
        .userrow {
          display: flex;
          flex-direction: row;
          gap: 16px;
          align-items: flex-start;

          .Fname,
          .Lname,
          .dob,
          .email,
          .gender,
          .phone,
          .addressLine1,
          .addressLine2,
          .city,
          .state,
          .postcode,
          .country {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;
            @include labelInput;
            width: 50vw;

            .mobileSelect {
              display: flex;
              gap: 5px;
              input {
                width: 100%;
              }

              select {
                width: 100px;
              }
            }

            input {
              width: 100%;
              box-sizing: border-box;
            }

            input[type="number"]::-webkit-outer-spin-button,
            input[type="number"]::-webkit-inner-spin-button {
              display: none;
            }
          }

          .country {
            input {
              background-color: rgba(208, 207, 207, 0.489);
            }
          }

          .initial {
            display: flex;
            margin-top: 32px;
          }
        }
        .Role {
          @include labelInput;

          label {
          }

          input {
            border: none;

            &:hover {
              border: none;
            }
          }

          .autoComplete {
            // padding: 12px 16px;
            padding: 0px;
            border-radius: 8px;
            background: $primary-white;
            color: rgba(111, 114, 122, 0.8);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-transform: capitalize;
          }
        }
      }

      .Role {
        @include labelInput;

        label {
        }

        input {
          border: none;

          &:hover {
            border: none;
          }
        }

        .autoComplete {
          // padding: 12px 16px;
          padding: 0px;
          border-radius: 8px;
          background: $primary-white;
          color: rgba(111, 114, 122, 0.8);

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-transform: capitalize;
        }
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancelBtn {
        border-radius: 8px;
        justify-content: center;
        color: $primary-blue;
        text-align: center;
        /* Body / Bold */
        // font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
      .sendBtn {
        display: flex;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: $primary-blue;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .referModalBox {
    .referModalTop {
      form {
        .name {
          flex-wrap: wrap;
          gap: 16px;

          .Fname,
          .Lname {
            width: 100%;
          }
        }
      }
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}
