@import "src/variable/colors.scss";

.card {
  position: relative;
  cursor: pointer;
  //   box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  //   border: 1px solid rgb(224 224 224 / 20%);
  //   border-radius: 5px;
  //   width: 220px;
  //   height: 140px;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgb(224 224 224 / 20%);
  display: flex;
  margin: 8px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 10px;
    transition: filter 0.3s;
  }

  .image {
    object-fit: cover;
    height: 100%;
  }

  .pdfLogo {
    width: 74px !important;
    height: 65px !important;
    align-items: center;
    padding: 0px 20px;
  }

  .btn {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
    transition: opacity 0.3s;

    .deleteIcon,
    .expandIcon {
      display: none;
      // position: absolute;
      // top: 56px;
      // right: 80px;
      color: #000000;
      border-radius: 5px;
      border: 0.5px solid #bcbaba;
      background: #f4f4f485;

      .icon {
        &:hover {
          color: red;
          cursor: pointer;
        }
      }
    }
    .expandIcon {
      right: 114px;

      .icon {
        &:hover {
          color: rgb(189, 189, 189);
          cursor: pointer;
        }
      }
    }
  }

  &:hover {
    img {
      filter: blur(4px);
    }

    .btn {
      opacity: 1;

      .deleteIcon,
      .expandIcon {
        display: block;
      }
    }
  }
}

.modalOver {
  background-color: transparent !important;
  box-shadow: none !important;

  .close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    position: absolute;
    width: 100%;
    top: 10px;
    right: 10px;
    button {
      border: none;
      background-color: #ffffff85;
      cursor: pointer;
      border-radius: 100%;
      height: 25px;
      &:hover {
        color: rgb(90, 89, 89);
      }
    }
  }

  .modalImage {
    width: 400px;
    height: 400px;
    border-radius: 10px;
  }

  iframe {
    height: 53vh;
    width: 76vw;
    max-width: 800px;
  }

  .modalBtn {
    position: absolute;
    bottom: 18px;
    display: flex;
    width: 100%;
    justify-content: center;

    .cancelBtn,
    .deleteBtn {
      border-radius: 8px;
      justify-content: center;
      color: white;
      text-align: center;
      background-color: $dark;
      /* Body / Bold */
      // font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-transform: none;
    }

    .cancelBtn {
      padding: 10px;
    }

    .deleteBtn {
      background-color: red;
    }
  }

  .modalBtnPdf {
    position: relative;
    margin: 10px;
    bottom: 0px;
  }
}

.modalOverPdf {
  background-color: #fff !important;
}

@media screen and (max-width: 400px) {
  .modalOver {
    .modalImage {
      width: 92vw;
    }
  }
}
