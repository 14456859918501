@import "../../variable/colors.scss";
.cardEditWrapper,
.cardPinWrapper {
  padding: 24px 40px;
  // width: 600px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    h5 {
      color: $primary-black;

      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }

  .body {
    height: 220px;

    .otpInputContainer {
      display: flex;
      flex-direction: column;
      margin: 20px 30px 0px 30px;
      gap: 25px;
      .pinInput {
        display: flex;
        flex-direction: column;
        gap: 5px;
        label {
          font-size: 14px;
          font-weight: 500;
        }

        input {
          border: 2px solid #97979a !important;
          border-radius: 8px;
          padding: 10px;
          margin: 5px;

          &:focus {
            border: 2px solid #3498db !important;
          }
        }
      }
    }

    div {
      display: flex;
    }

    .label {
      color: $secondary;
      display: block;
      width: 250px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      gap: 6px;
    }

    .value {
      color: $dark;

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      cursor: pointer;
    }
  }
}

.cardPinWrapper {
  // width: fit-content;
  width: 85%;
}
.bottom {
  background: $secondary-gray-1;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  .bottomContainer {
    padding: 8px 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .cancelBtn {
      border-radius: 8px;
      justify-content: center;
      color: $dark;
      text-align: center;
      /* Body / Bold */

      // font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-transform: none;
    }

    .saveBtn {
      display: flex;
      height: 48px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: $dark;
      font-weight: 600;
      line-height: 24px;
      text-transform: none;
    }
  }
}

.filterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px 0px;
  gap: 30px;
  h3 {
  }
  .filterContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    // padding: 20px 40px;


    @include labelInput;

    .holderName, .acessType, .user {

      input {
        height: 30px;
      }

      .error {
        margin: 0px;
      }

      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .user {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          font-size: 10px;
          text-transform: none;
        }

        .toggleBodyOver {
          height: 25px;
          width: 165px;
          border-radius: 15px;

          &:before {
            font-size: 13px;
            top: -2px;
            right: 19px;
          }

          &:after {
            height: 8px;
            font-size: 13px;
            /* top: 2px; */
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 5px 6px 7px 6px;
            border-radius: 12px;
          }
        }

        .myToggleSwitch {
          input[type="checkbox"] {
            cursor: pointer;
            width: 50px;
            height: 25px;
            opacity: 0;
            position: absolute;
            top: 0;
            z-index: 1;
            margin: 0px;

            &:checked {
              + label.toggleBodyOver {
                &:after {
                  content: attr(data-on);
                  left: 73px;
                  cursor: pointer;
                  padding: 5px 6px 7px 6px;
                }

                &:before {
                  content: attr(data-off);
                  right: auto;
                  left: 15px;
                  top: -2px;
              }
                }
              }
            }
          }
        }

      }
      .content {
        .Autocomplete {
          display: flex;
          width: 100%;

          input {
            border: none;
          }
        }

        .userInfo {
          display: flex;
          flex-direction: column;
          padding: 17px 9px;
          border: 1px solid #ccc;
          border-radius: 4px;
          margin-top: 5px;
          background-color: #97979830;
      

          label{
            color: #2b2b2b;
          }
          
          span {
            color: $dark;
          }

        }
      }
    }
  }
  .bottom {
    background: #f7f7f9;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;
    padding: 8px 40px 15px;;

    .orderCardBtn {
      display: flex;
      height: 48px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: $dark;
      font-weight: 600;
      line-height: 24px;
      text-transform: none;
    }
    .clearBtn {
      border-radius: 8px;
      justify-content: center;
      color: $dark;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-transform: none;
    }
  }


.mt10 {
  margin-top: 10px;
}

.progress {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;
  margin: 5px 0px 0px 30px;

  .errIcon {
    width: 15px;
  }
}

.pinModalOver {
  min-width: 392px;
}
