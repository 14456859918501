@import "src/variable/colors.scss";

gradient {
  @include gradient;
}

.userHead {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid $gray-300;
  background: $primary-white;

  // width: 368px;
  // height: 336px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    .headL {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
      h3 {
        color: $dark;

        /* Large bold */
        // font-family: Noto Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 25.2px */
      }
      p {
        color: $secondary;

        /* Small */
        // font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
    }
    .headR {
      display: flex;
      // padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;

      border-radius: 6px;
      border: 1px solid $gray-300;
      background: $primary-white;

      /* Shadow cards */
      box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
      cursor: pointer;

      button {
        padding: 3px;
      }

      .editIcon {
        width: 14px;
        height: 14px;
        color: #64748b;
      }
    }
  }
  .userInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    .label {
      color: $secondary;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      display: flex;
      align-items: flex-start;
      gap: 6px;
      .value {
        color: $dark;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }

    .roleLabel {
      display: flex;
      gap: 10px;
      align-items: center;

      .label {
        align-items: center;

        .autoComplete {
          input {
            padding: 0px;
          }
        }
      }

      .updateBtn {
        background-color: $dark;
      }

      .closeIcon {
        cursor: pointer;

        &:hover {
          background: rgba(146, 147, 148, 0.325);
          animation: gradient 200ms ease-out;
          border-radius: 100%;
        }

        &:active {
          animation: gradient 200ms ease-out;
          background: rgba(52, 70, 100, 0.325);
        }
      }

      .editIcon {
        width: 14px;
        height: 14px;
        color: #64748b;
        cursor: pointer;
      }
    }
    .toggleBodyOver {
      height: 25px;
      width: 145px;
      border-radius: 15px;

      &:before {
        font-size: 13px;
        top: 0px;
        right: 17px;
      }

      &:after {
        height: 10px;
        font-size: 13px;
        width: 60px;
        top: 1.2px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 5px 6px 7px 6px;
        border-radius: 12px;
      }
    }

    .myToggleSwitch {
      input[type="checkbox"] {
        cursor: pointer;
        width: 50px;
        height: 25px;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 1;
        margin: 0px;

        &:checked {
          + label.toggleBodyOver {
            &:after {
              content: attr(data-on);
              left: 69px;
              cursor: pointer;
              padding: 5px 6px 7px 6px;
              width: 60px;
              top: 1.2px;
              background-color: #52ad52;
              color: white;
            }

            &:before {
              content: attr(data-off);
              right: auto;
              left: 13px;
              top: 0px;
            }
          }
        }
      }
    }

    .bottomInfo {
      display: flex;
      align-items: flex-start;
      gap: 6px;
    }
  }
}

.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }

      .setting {
        display: flex;
        // width: 675px;
        padding: 0px 31px 0px 13px;
        align-items: flex-start;
        gap: 10px;
        flex-direction: column;
        text-align: left;
        color: rgb(63, 63, 63);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 21px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btnL {
        display: flex;
        flex-direction: row;
        gap: 24px;
        .removeBtn {
          display: flex;
          /* height: 40px; */
          padding: 0px 5px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid #fa0000;
          box-shadow:
            0px 4px 8px 0px rgba(163, 59, 8, 0.08),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          color: #fa0000;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: none;
        }

        .reportBtn {
          color: #324056;
          text-align: center;

          /* Body / Bold */

          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          text-transform: none;
        }
      }

      .btnR {
        .sendBtn {
          display: flex;
          /* height: 48px; */
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: #f42409;

          font-weight: 600;
          line-height: 24px;
          text-transform: none;
        }
      }
    }
  }
}
