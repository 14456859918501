@import "src/variable/colors.scss";
.walletPage {
  display: flex;
  flex-direction: column;
  background-color: #f9fafb;

  .walletPageWelCard {
    display: flex;
    width: 100%;
    background: rgb(156, 25, 121);
    background: rgb(139, 212, 247);
    background: linear-gradient(122deg, rgb(255 255 255) 0%, rgb(106, 72, 155) 100%);
  }

  .userPageHead {
    display: flex;
    margin: 24px;
    gap: 15px;
    margin-bottom: 0;

    .infoCard,
    .preferenceCard {
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 24px;
      margin: 10px 0px 19px 12px;
      flex: 1 0 0;
      border-radius: 12px;
      border: 1px solid $gray-300;
      background: $primary-white;
      min-width: 23vw;
      .head {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        .headL {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 3px;
          h3 {
            color: $dark;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
          }
          p {
            color: $secondary;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
        .headR {
          display: flex;
          padding: 8px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 6px;
          border: 1px solid $gray-300;
          background: $primary-white;
          box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
          cursor: pointer;

          .editIcon {
            width: 14px;
            height: 14px;
            color: #64748b;
          }
        }
        Button {
          background-color: $dark;
        }
      }
      .walletInfo,
      .preferenceInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
        width: 100%;
        .label {
          color: $secondary;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;

          display: flex;
          align-items: center;
          gap: 6px;
          .value {
            color: $dark;

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }

          @include labelInput;
          input {
            padding: 6px 10px;
          }
        }

        .allowedLimit,
        .spendLimit {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;

          .label {
            input {
              border: none;
              width: 50px;
            }

            .value {
            }
          }

          .sliderInput {
            display: flex;
            justify-content: space-between;
          }
          .slider {
            display: flex;
            flex-direction: column;
            .maxmin {
              font-size: 12px;
              display: flex;
              justify-content: space-between;
            }
          }
        }

        .error {
          display: flex;
          color: rgb(254, 44, 44);
          gap: 4px;
          font-size: 12px;
          font-style: normal;
          line-height: 20.8px;
          align-items: center;
          width: 100%;
          justify-content: flex-end;
          margin-top: -6px;
          margin-bottom: -7px;
        
          .errIcon {
            width: 15px;
          }
        }
      }
    }
    .settingCard {
      width: 45%;
      display: flex;
      margin: 10px;
      margin-bottom: 20px;
    }
  }

  .userPageMid {
    display: flex;
    margin: 0px 24px;
    gap: 15px;
    margin-bottom: 0;

    .connectCard {
      margin: 10px 0px 20px 10px;
      width: 45%;
      display: flex;
      // margin: 10px;
      // margin-bottom: 20px;
    }
    .depositCard {
      // margin: 10px 0px 19px 12px;
      // margin: 10px 12px 19px 12px;
      width: 40vw;
      display: flex;

      .recentTransOver {
        width: 100%;
        max-width: 100%;
      }
    }
    .transCard {
      width: auto;
      columns: 2;
      column-gap: 7px;

      .WalletTransCardOver {
        display: flex;
        margin-bottom: 10px;

        .balCardOver {
          display: flex;
          text-align: left;
          width: 14vw;
        }
      }
    }
  }

  .userPageBottom {
    display: flex;
    margin: 24px;
    margin-top: 0px;

    .recentTransOver {
      width: 100%;
      max-width: 100%;
    }
    // gap: 30px;
  }
}

@media screen and (max-width: 1330px) {
  .walletPage {
    .userPageHead {
      .infoCard,
      .preferenceCard {
        min-width: 180px;
      }
      .transCard {
        .WalletTransCardOver {
          .balCardOver {
            // width: 35vw;
          }
        }
      }
    }
  }
}
// @media screen and (max-width: 1220px) {
//   .walletPage {
//     .userPageHead {
//       // flex-wrap: wrap;
//       .transCard {
//         .WalletTransCardOver {
//           .balCardOver {
//             // width: 35vw;
//           }
//         }
//       }
//     }
//   }
// }
@media screen and (max-width: 900px) {
  .walletPage {
    .userPageHead {
      flex-wrap: wrap;

      .infoCard,
      .preferenceCard {
        min-width: 250px;
        margin: 10px 0px 0px 12px;
      }

      .settingCard {
        width: 100%;
        margin-right: 0px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .walletPage {
    .userPageMid {
      flex-direction: column;
      gap: 15px;

      .connectCard {
        width: 99%;
        margin: 10px 10px 0px 10px;

      }

      .depositCard {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .walletPage {
    .userPageMid {
      flex-direction: column;
      gap: 5px;
      .connectCard {
        width: auto;
        display: flex;
      }
      // .depositCard {
      //   margin: 10px 0px 19px 12px;
      //   width: auto;
      // }
      .transCard {
        .WalletTransCardOver {
          .balCardOver {
          }
        }
      }
    }
  }
}
