@import "src/variable/colors.scss";

* {
  margin: 0;
  padding: 0;
}

gradient {
  @include gradient;
}

.WalletCard {
  .card {
    display: flex;
    width: 213px;
    height: 165px;
    padding: 10px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid $gray-200;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
    margin: 10px;

    .cardHolder {
      display: flex;
      width: 180px;
      align-items: center;
      gap: 12px;

      img {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        border-radius: 50%;
      }

      .cardHolderName {
        display: flex;
        padding: 0px 12px;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;

        h3,
        h2,
        h1 {
          display: flex;
          flex-direction: column;
          align-self: stretch;
          color: $dark;
          font-size: 16px;
          font-weight: 600;
          line-height: 162.5%;
          letter-spacing: -0.8px;
          text-align: left;
        }

        p {
          display: flex;
          flex-direction: column;
          align-self: stretch;

          color: $secondary;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          text-align: left;
        }
      }
    }

    .cardBalance {
      display: flex;
      padding-right: 0px;
      align-items: center;
      justify-content: space-between;
      width: 180px;

      .cardWalletBal {
        display: flex;
        gap: 10px;

        .walletBalH {
          display: flex;
          // width: 90px;
          flex-direction: column;
          justify-content: center;
          color: $secondary;
          font-size: 15px;
          font-weight: 400;
          line-height: 150%;
          text-align: left;
        }
        .walletBalP {
          display: flex;
          width: auto;
          flex-direction: column;
          justify-content: center;
          color: $dark;
          font-size: 17px;
          font-weight: 600;
          line-height: 137%;
          letter-spacing: -0.8px;
          padding-right: 12px;
          text-align: left;
        }
      }

      .cardCardBal {
        .cardCardBalH {
          display: flex;
          width: 90px;
          flex-direction: column;
          justify-content: center;
          color: var(--secondary, #64748b);
          font-size: 15px;
          font-weight: 400;
          line-height: 150%;
          text-align: left;
        }
        .cardCardBalP {
          display: flex;
          width: auto;
          flex-direction: column;
          justify-content: center;
          color: $dark;
          font-size: 17px;
          font-weight: 600;
          line-height: 137%;
          letter-spacing: -0.8px;
          padding-right: 12px;
          text-align: left;
        }
      }
    }

    &:hover {
      // background: rgba(28, 52, 90, 0.12);
      border-radius: 8px;
      // border-top-right-radius: 8px;
      // border-bottom-right-radius: 8px;
      // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      -webkit-backdrop-filter: blur(3.5px);
      backdrop-filter: blur(3.5px);
      // border: 1px solid rgba(28, 52, 90, 0.25);
      box-shadow: 0px 1px 12px 9px rgba(182, 182, 182, 0.217);
      // margin-top: 2px;
      transform: translateY(-0.1ch);
    }

    &:active {
      animation: gradient 100ms;
      background: rgba(52, 70, 100, 0.325);
    }
  }
}

@media screen and (max-width: 800px) {
  .WalletCard {
    .card {
      width: 200px;
      height: 140px;
      padding: 10px;
      margin: 2px;

      .cardHolder {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 12px;

        .cardHolderName {
          padding: 0px 12px;

          h3,
          h2,
          h1 {
            font-size: 14px;
            font-weight: 600;
            line-height: 162.5%;
            letter-spacing: -0.8px;
          }

          p {
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }

      .cardBalance {
        padding-right: 0px;
        width: 100%;

        .cardWalletBal {
          .walletBalH {
            font-size: 15px;
            font-weight: 400;
            line-height: 150%;
          }
          .walletBalP,
          .cardCardBalP {
            font-size: 13px;
            font-weight: 600;
            line-height: 137%;
            letter-spacing: -0.8px;
            padding-right: 12px;
          }
        }

        // .cardCardBal {
        //   .cardCardBalH {
        //     width: 90px;
        //     font-size: 12px;
        //     font-weight: 400;
        //     line-height: 150%;
        //   }
        //   .cardCardBalH {
        //     font-size: 12px;
        //     font-weight: 600;
        //     line-height: 137%;
        //     letter-spacing: -0.8px;
        //     padding-right: 12px;
        //   }
        // }
      }
    }
  }
}
