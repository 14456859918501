@import "src/variable/colors.scss";

.newPaymentCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 300px;
  margin: 33px 15px 15px 25px;
  
  .select {
    display: flex;
    height: 56px;
    // box-shadow: 0px 4px 4px #00000040;
    padding: 20px 5px;
    flex-grow: 1;
  }

  .amountCard {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #0000004c;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #00000040;
    display: flex;
    flex-direction: column;
    gap: 18px;
    height: 158px;
    justify-content: center;
    overflow: hidden;
    padding: 0px 35px 2px;

    .title {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: normal;
      text-align: center;
    }
    .amount {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .amtValue {
        display: flex;
        align-items: center;
        background-color: #d9d9d9;
        border: 1px solid #0000004c;
        border-radius: 10px;
        box-shadow: 0px 4px 4px #00000040;
        gap: 10px;
        height: 25px;
        justify-content: center;
        padding: 2px 10px;
      }
    }
    .amountInput {
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border: 1px solid #0000004c;
        box-shadow: 0px 4px 4px #00000040;
        border-radius: 10px;
        padding: 11px 25px;
        gap: 10px;
      }
    }
  }
  .anotherBtn {
    // margin: 0px 10px;
    background-color: #fb8278;
    color: #000;
    border-radius: 10px;
    font-weight: 500;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    // margin: 0px 10px;
    gap: 20px;
    .backBtn,
    .continueBtn {
      font-size: 16px;
      font-weight: 700px;
      height: 56px;
      width: 100%;
      background-color: $dark;
      border-radius: 10px;
    }
    .backBtn {
      background-color: #d9d9d9;
      color: #000;
    }
  }
}

.totalAmountCard {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #0000004c;
  box-shadow: 0px 4px 4px #00000040;
  padding: 30px 35px;
  border-radius: 10px;
  justify-content: center;
  width: 309px;
  .tilte {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
  }
  .totalamount {
    font-size: 30px;
    font-weight: 500;
    color: green;
  }
  .addedContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 5px;
    .addedValue {
      display: flex;
      justify-content: space-between;
      padding: 0px 8px;
      align-items: center;

      .walletName {
      }
      .amount {
        @include labelInput;
        input {
          padding: 4px 5px;
          border: 1px solid #7c7c7c;
          width: 45px;
          background-color: #dcdada7e;
        }
      }
    }
  }

  .hr {
    // border: 1px solid #0000004c;
    background-color: #000;
    width: 100%;
    height: 1px;
    margin: 0px;
  }
}
.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}

@media screen and (max-width: 400px) {
  .totalAmountCard {
    width: 73%;
  }

  .newPaymentCard {
    .amountCard {
      .amount {
        gap: 9px;
        .amtValue {
          padding: 1px 7px;
        }
      }
      .amountInput {
        input {
          padding: 9px 20px;
        }
      }
    }
  }
  
}
