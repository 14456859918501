@import "../../variable/colors.scss";

.orgList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: auto;
  padding: 16px 0px;
  border-radius: 12px;
  border: 1px solid $gray-200;
  background: $primary-white;
  /* Shadow cards */
  box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);

  .orgListHead {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;

    .orgListHeadIn {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;

      .orgListHeadD {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;

        h3 {
          color: $dark;

          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 162.5%;
          letter-spacing: -0.8px;
        }

        p {
          color: $secondary;

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
      .orgListHeadBtn {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .filterBtn {
          height: 35px;
          align-items: center;
          border-radius: 6px;
          border: 1px solid $gray-500;
          background: $primary-white;
          // margin: 0px 16px;

          color: $dark;
          /* Xs bold */
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
        }

        .addorgBtn {
          display: flex;
          height: 35px;
          background: $dark;
          border: radius 6px;
          align-items: center;

          color: $primary-white;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: none;
        }
      }
    }

    .orgListFilter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      // flex-wrap: wrap;

      .filterSearch {
        display: flex;
        gap: 15px;
      }

      .orgListSearch {
        display: flex;
        gap: 5px;

        .searchBar {
          height: 40px;
          width: 290px;
        }
      }
    }
  }

  .orgListTable {
    display: flex;
    width: 100%;
    overflow-x: auto;

    .tableIn {
      width: 100%;
      table {
        width: 100%;
        border: 1px solid $gray-300;

        border-collapse: collapse;

        thead {
          // display: flex;
          width: 689px;
          height: 40px;
          border: 1px solid $gray-300;
          background: $gray-100;
          background: $gray-100;

          tr th {
            padding: 8px 0px;
            color: rgba(100, 116, 139, 0.7);
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-align: left;
            padding: 0px 20px;
          }
        }
      }

      tbody {
        tr {
          width: 689px;
          padding: 0px 20px;
          border: 1px solid var(--gray-400, #ced4da);

          td {
            width: 689px;
            padding: 9px 20px;

            text-align: left;
            color: $secondary;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;

            .tableMember {
              display: flex;
              align-items: center;
              gap: 12px;
              flex: 1 0 0;
              .tableMemberImg {
                img {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                }
              }
              .tableMemberDetail {
                display: flex;
                padding: 0px 12px;
                flex-direction: column;
                align-items: flex-start;
                flex: 1 0 0;
                .tableMemberName {
                  align-self: stretch;
                  color: $dark;

                  /* h6 */
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 162.5%; /* 26px */
                  letter-spacing: -0.8px;
                }
                .tableMemberEmail {
                  align-self: stretch;
                  color: $secondary;

                  /* Small */
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%; /* 21px */
                }
              }
            }

            .tableRole {
              .tableRoleName {
                color: $dark;

                /* h6 */
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 162.5%; /* 26px */
                letter-spacing: -0.8px;
              }
              .tableroleText {
                color: $secondary;
                /* Small */
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 21px */
              }
            }

            .tableStatus {
              // display: flex;
              // width: 200px;
              padding: 8px 0px;
              gap: 8px;
              .green {
                display: flex;
                /* padding: 4px 4px; */
                align-items: center;
                border-radius: 6px;
                border: 1px solid rgba(103, 194, 58, 0.45);
                background: rgba(103, 194, 58, 0.1);
                width: 50px;
                height: 23px;

                /////text///////
                color: $success;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%;
                justify-content: center;
              }
              .grey {
                display: flex;
                /* padding: 4px 4px; */
                align-items: center;
                border-radius: 6px;
                border: 1px solid rgba(100, 116, 139, 0.45);
                background: rgba(100, 116, 139, 0.1);
                width: 63px;
                height: 23px;

                /////text///////
                color: $secondary;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%;
                justify-content: center;
              }
              .yellow {
                display: flex;
                /* padding: 4px 4px; */
                align-items: center;
                border-radius: 6px;
                border-radius: 6px;
                border: 1px solid rgba(100, 116, 139, 0.45);
                background: rgba(215, 156, 4, 0.37);
                width: 89px;
                height: 23px;

                /////text///////
                color: $secondary;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%;
                justify-content: center;
              }
            }

            .tableLastActive {
              display: flex;
              width: 200px;
              padding: 8px 0px;
              align-items: flex-start;
              gap: 8px;

              color: $secondary;
              text-align: center;

              /* Small */
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 21px */
            }
          }
        }
      }
    }
  }
  .paginationWrapper {
    display: flex;
    justify-content: center;
    // margin-top: 10px;
  }

  //   }
}

.modaluiOver {
  max-height: 85vh;
  overflow-y: auto;
  max-width: 80% !important;
}

@media screen and (max-width: 800px) {
  .orgList {
    .orgListHead {
      .orgListHeadIn {
        .orgListHeadD {
          p {
            font-size: 12px;
          }
        }
        .orgListHeadBtn {
          display: flex;
          align-items: flex-start;
          gap: 8px;

          .addorgBtn {
            font-size: 12px;
          }
        }
      }

      .orgListFilter {
        gap: 10px;
        flex-wrap: wrap;
      }
    }

    .orgListTable {
      tbody {
        tr {
          td {
            font-size: 13px;

            .tableMember {
              .tableMemberDetail {
                .tableMemberName {
                  font-size: 13px;
                }
                .tableMemberEmail {
                  font-size: 10px;
                }
              }
            }

            .tableRole {
              .tableRoleName {
                font-size: 14px;
              }
              .tableroleText {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .modaluiOver {
    width: 90% !important;
  }
}

@media screen and (max-width: 450px) {
  .orgList {
    .orgListHead {
      .orgListFilter {
        .filterSearch {
          gap: 10px;
          flex-wrap: wrap;
        }
      }
    }

    .orgListTable {
      tbody {
        tr {
          td {
            font-size: 13px;

            .tableMember {
              .tableMemberDetail {
                .tableMemberName {
                  font-size: 13px;
                }
                .tableMemberEmail {
                  font-size: 10px;
                }
              }
            }

            .tableRole {
              .tableRoleName {
                font-size: 14px;
              }
              .tableroleText {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
