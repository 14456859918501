@import "../../../variable/colors.scss";

.rootContainer {
  display: flex;
  justify-content: center;
  margin: 25px;
  flex-direction: column;

  .modalTop {
    margin: 0px 40px;
    margin-right: 15px;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      box-sizing: border-box;
      margin-right: 15px;

      .generalCompany,
      .mainContact,
      .companyAddress,
      .UserDetail {
        display: flex;
        flex-direction: column;
        gap: 16px;
        box-sizing: border-box;
        .close {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          h5 {
            color: $primary-black;

            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }
          button {
            border: none;
            background-color: $primary-white;
            cursor: pointer;
            margin-right: -40px;
          }
        }

        .radioOption {
          display: flex;
          gap: 25px;

          label {
            display: flex;
            gap: 10px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }
        }

        .newUser,
        .existingUser {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .email {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;
            @include labelInput;
            width: 50vw;
          }

          .name,
          .userrow {
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: flex-start;

            .Fname,
            .Lname,
            .dob,
            .email,
            .gender,
            .phone,
            .addressLine1,
            .addressLine2,
            .city,
            .state,
            .postcode,
            .country {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              gap: 8px;
              @include labelInput;
              width: 50vw;

              .mobileSelect {
                display: flex;
                gap: 5px;
                input {
                  width: 100%;
                }

                select {
                  width: 100px;
                }
              }

              input {
                width: 100%;
                box-sizing: border-box;
              }

              input[type="number"]::-webkit-outer-spin-button,
              input[type="number"]::-webkit-inner-spin-button {
                display: none;
              }
            }

            .initial {
              display: flex;
              margin-top: 32px;
            }
          }
          .Role {
            @include labelInput;

            label {
            }

            input {
              border: none;

              &:hover {
                border: none;
              }
            }

            .autoComplete {
              // padding: 12px 16px;
              padding: 0px;
              border-radius: 8px;
              background: $primary-white;
              color: rgba(111, 114, 122, 0.8);

              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              text-transform: capitalize;
            }
          }
        }

        .existingUser {
          .email {
            .existingUserEmail {
              display: flex;
              gap: 6px;

              .getUser {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                background: $dark;
                text-transform: none;
                padding: 0px 10px;
                border-radius: 8px;
                margin: 1px 0px;
              }
            }
          }
        }
      }

      .row {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        // flex-wrap: wrap;

        .companyName,
        .abnName,
        .mobile,
        .role,
        .website,
        .address,
        .city,
        .state,
        .postcode,
        .name,
        .role,
        .email,
        .phone,
        .facebook,
        .linkedIn,
        .instagram,
        .addressLine1,
        .addressLine2,
        .country {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: 8px;
          @include labelInput;
          box-sizing: border-box;
          align-items: flex-start;

          .mobileSelect {
            display: flex;
            gap: 5px;
            input {
              width: 100%;
            }

            select {
              width: 100px;
            }
          }

          .inputWerror {
            width: 100%;
          }

          input {
            width: 100%;
            box-sizing: border-box;
          }

          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            display: none;
          }

          label {
            width: 125px;
          }
        }

        .linkedIn {
          input {
            width: 49%;
          }
        }
      }

      // .rowII {
      //   .mobile {
      //     input {
      //       height: 26px;
      //     }
      //   }
      // }
    }
  }
}

.modalBottom {
  //   background: $secondary-gray-1;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  .referModalBtn {
    padding: 16px 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 0px;

    .sendBtn {
      display: flex;
      height: 48px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: #1e293b;
      font-weight: 600;
      line-height: 24px;
      text-transform: none;
      width: 115px;
      font-size: 16px;
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}

.success {
  display: flex;
  color: rgb(25, 87, 20);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;
  .successIcon {
    width: 15px;
  }
}

@media (max-width: 1348px) {
  .rootContainer {
    .modalTop {
      form {
        .generalCompany,
        .mainContact {
          .row,
          .rowII {
            flex-direction: column;

            .companyName,
            .abnName,
            .mobile,
            .role,
            .website,
            .address,
            .city,
            .state,
            .postCode,
            .name,
            .role,
            .email,
            .phone,
            .facebook,
            .linkedIn,
            .instagram {
              width: 100%;
              input {
                width: 100% !important;
              }

              textarea {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .rootContainer {
    .modalTop {
      margin: 0px 10px;
      margin-right: 50px;

      form {
        .generalCompany,
        .mainContact {
          .close {
            h5 {
              font-size: 15px;
            }
          }
          .row,
          .rowII {
            .companyName,
            .abnName,
            .mobile,
            .role,
            .website,
            .address,
            .city,
            .state,
            .postCode,
            .name,
            .role,
            .email,
            .phone,
            .facebook,
            .linkedIn,
            .instagram {
              label {
                font-size: 10px;
                font-weight: 600;
              }
              input {
                padding: 6px 16px;
                font-size: 11px;
              }

              textarea {
                padding: 6px 16px;
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .rootContainer {
    .modalTop {
      .UserDetail {
        .newUser {
          .name {
            .Fname,
            .Lname {
              width: 24vw !important;
            }
          }
        }
      }
    }
  }
}

.progress {
  display: flex;
  justify-content: center;
}
